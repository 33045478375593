import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CardContent,
  Typography,
  Grid,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import moment from "moment";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Payment from "../Admin/Payment/Payment";
import { useSnackbar } from "notistack";
import Controller from "../../Service/ApiController";
import * as Icons from "@mui/icons-material";
import { apiEndPoint } from "../../Service/ApiConstant";
import axios from "axios";
import { BASE_URL } from "../../config";

const FlippingCard = styled(Box)(({ theme, colors, flipped }) => {
  const [color1, color2] = colors.split(",");

  return {
    perspective: "150rem",
    position: "relative",
    minHeight: "32rem",
    cursor: "pointer",
    "& .card__side": {
      transition: "transform 0.8s ease",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backfaceVisibility: "hidden",
      borderRadius: "10px",
      overflow: "hidden",
      boxShadow: "0 1.5rem 4rem rgba(0, 0, 0, 0.15)",
    },
    "& .card__side--front": {
      transform: flipped ? "rotateY(180deg)" : "rotateY(0)",
      backgroundColor: "#fff",
      background: `linear-gradient(-45deg, ${color1}, ${color2})`,
    },
    "& .card__side--back": {
      transform: flipped ? "rotateY(0)" : "rotateY(180deg)",
      background: `linear-gradient(-45deg, ${color2}, ${color1})`,
    },
  };
});

const Purchase = () => {
  const user_uid = sessionStorage.getItem("userUid");
  const [modalOpen, setModalOpen] = useState(false);
  const [view, setView] = useState("pricing");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [hasUsedFreePlan, setHasUsedFreePlan] = useState(true);
  const [flippedCards, setFlippedCards] = useState({});
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [DegradeOpen, setDegradeOpen] = useState(false);
  const [userdata, setUserdata] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(0);
  const [planData, setPlanData] = useState([]);

  const [region, setRegion] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState("AUD");

  const getIconComponent = (iconName) => {
    const IconComponent = Icons[iconName];
    return IconComponent ? (
      <IconComponent style={{ fontSize: "45px" }} />
    ) : null;
  };

  const fetchRegion = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/data/address/fetch_ip`);
      const country = response.data.location.country || "AU";
      const currency = response.data.location.currency || "AUD";
      setRegion(country);
      setCurrency(currency);
      fetchExchangeRate(country);
    } catch (error) {
      console.error("Failed to fetch region", error);
    }
  };

  const fetchExchangeRate = async (country) => {
    let currency = "USD";
    if (country === "IN") {
      currency = "INR";
      setCurrencySymbol("₹");
    } else if (country === "US") {
      currency = "USD";
      setCurrencySymbol("$");
    } else if (country === "AU") {
      currency = "AUD";
      setCurrencySymbol("A$");
    }
    try {
      const response = await axios.get(`https://open.er-api.com/v6/latest/AUD`);
      setExchangeRate(response.data.rates[currency]);
    } catch (error) {
      console.error("Failed to fetch exchange rate", error);
    }
  };

  useEffect(() => {
    fetchRegion();
  }, []);

  

  const fetchUser = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        apiEndPoint.USER_ROLE_FETCHER + user_uid,
        "GET"
      );
      if (result.success === true) {
        setUserdata(result.data);
      } else {
        enqueueSnackbar(`${result.data}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(`An Error Occured In Fetching The data`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const fetchPlan = async () => {
    try {
      const result = await Controller.ApiController(
        "",
        `${apiEndPoint.All_PLAN_FETCHER}`,
        "GET"
      );
      if (result.success === true) {
        const plans = result.data.plans.map((plan) => {
          if (plan.planType.toLowerCase() === "yearly") {
            plan.basicDetails.price *= 12;
          }
          return plan;
        });

        setPlanData(plans);
      } else {
        enqueueSnackbar(result.data, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("Request Error", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  useEffect(() => {
    fetchPlan();
    fetchUser();
  }, []);

  function validatePlanSelection(currentPlan, selectedPlan) {
    if (selectedPlan.planType === "addon" ||selectedPlan.planType === "offers") {
      return { valid: true };
    }
    if (currentPlan.plan_name === "Free") {
      return { valid: true };
    }
    const currentPlanDetails = planData.find(
      (plan) => plan.basicDetails.title === currentPlan.plan_name
    );
    const selectedPlanDetails = planData.find(
      (plan) => plan.basicDetails.title === selectedPlan.basicDetails.title
    );

    if (!currentPlanDetails || !selectedPlanDetails) {
      return { valid: false, message: "Invalid plan selection." };
    }

    if (
      selectedPlanDetails.basicDetails.price <
      currentPlanDetails.basicDetails.price
    ) {
      return {
        valid: false,
        message: "Degrade cannot be done, contact support.",
      };
    }

    return { valid: true };
  }

  const handleSelectPlan = (plan) => {
    const validation = validatePlanSelection(userdata.plan_details, plan);
    if (!validation.valid) {
      setDegradeOpen(true);
      return;
    }
    if (plan.basicDetails.title.includes("Free") && hasUsedFreePlan) {
      const data = "You have already used the Free plan.";
      enqueueSnackbar(`${data}`, {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    if (
      userdata.plan_details.plan_name === "Free" &&
      plan.planType === "addon"
    ) {
      const data = "Add On cannot be Added To free Tier";
      enqueueSnackbar(`${data}`, {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    if (userdata.total_credits === 406802 && plan.planType === "addon") {
      const data = "For Unlimited Packages No Add On ";
      enqueueSnackbar(`${data}`, {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    if (userdata) {
      const currentPlanValidity = moment(
        userdata.plan_details.plan_validity_date
      );
      const isPlanValid = currentPlanValidity.isAfter(moment());
      const hasCredits = userdata.total_credits > 0;

      if (isPlanValid && hasCredits && plan.planType !== "addon") {
        setSelectedPlan(plan);
        setConfirmationOpen(true);
      } else {
        setSelectedPlan(plan);
        setModalOpen(true);
      }
    }
  };

  const handleConfirmProceed = () => {
    setConfirmationOpen(false);
    setSelectedPlan(selectedPlan);
    setModalOpen(true);
  };

  const handleCancel = () => {
    setConfirmationOpen(false);
  };
  const handleDegradeCancel = () => {
    setDegradeOpen(false);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleProceed = () => {
    if (!paymentType) {
      enqueueSnackbar("Please select a payment method.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    setModalOpen(false);
    setView("payment");
  };

  const handleCardClick = (id) => {
    setFlippedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const groupedPlans = planData.reduce((acc, plan) => {
    (acc[plan.planType] = acc[plan.planType] || []).push(plan);
    return acc;
  }, {});

  const planTypes = Object.keys(groupedPlans);

  const predefinedOrder = ["monthly", "yearly", "addon"];

  const sortedPlanTypes = planTypes.sort((a, b) => {
    return predefinedOrder.indexOf(a) - predefinedOrder.indexOf(b);
  });
  return view === "pricing" ? (
    <>
      <Box>
        <Paper>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {sortedPlanTypes.map((planType, index) => (
              <Tab key={planType} label={planType} />
            ))}
          </Tabs>
          <Box sx={{ p: 3 }}>
            {planTypes.map((planType, index) => (
              <Grid
                key={planType}
                role="tabpanel"
                hidden={selectedTab !== index}
              >
                <Grid container>
                  {groupedPlans[planType].map((plan) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={plan.plan_id}
                      padding={2}
                    >
                      <FlippingCard
                        colors={plan.basicDetails.colors}
                        display="flex"
                        textAlign="center"
                        flipped={flippedCards[plan.plan_id]}
                        onClick={() => handleCardClick(plan.plan_id)}
                        sx={
                          plan.planType.includes("addon")
                            ? { minHeight: "20rem" }
                            : {}
                        }
                      >
                        <Box
                          className="card__side card__side--front"
                          sx={{ padding: "0.5rem 1rem 0 !important" }}
                        >
                          <Box sx={{ textAlign: "center", padding: "1rem" }}>
                            {getIconComponent(plan.basicDetails.icon)}
                            <Typography
                              variant="h4"
                              component="h4"
                              sx={{
                                color: "#000",
                                marginTop: "0.5rem",
                                fontWeight: "900",
                                textTransform: "uppercase",
                                fontSize: "25px",
                              }}
                            >
                              {plan.basicDetails.title}
                            </Typography>
                          </Box>
                          <Box
                            className="card__details"
                            sx={{
                              flexGrow: 1,
                              padding: "0.5rem 1rem 0 !important",
                            }}
                          >
                            {plan.basicDetails.details.map((detail, idx) => (
                              <Box
                                key={idx}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "0.9rem",
                                }}
                              >
                                <CheckCircleIcon
                                  style={{
                                    color: "white",
                                    marginRight: "0.5rem",
                                  }}
                                />
                                <Typography
                                  variant="h6"
                                  component="p"
                                  sx={{
                                    color: "#fff",
                                    fontSize: "15px",
                                    maxWidth: "100%",
                                    fontWeight: "600",
                                  }}
                                >
                                  {detail}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box className="card__side card__side--back">
                          <CardContent>
                            <Box className="card__cta">
                              <Typography
                                className="card__price-only"
                                variant="h4"
                                sx={
                                  plan.planType.includes("addon")
                                    ? { color: "#000", marginTop: "3rem" }
                                    : { color: "#000", marginTop: "9rem" }
                                }
                              >
                                Only
                              </Typography>
                              <Typography
                                className="card__price-value"
                                variant="h4"
                                sx={{
                                  color: "#000",
                                  marginTop: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {currencySymbol}
                                {(
                                  plan.basicDetails.price * exchangeRate
                                ).toFixed(2)}
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                  color: "#f403d1",
                                  backgroundColor: "Yellow",
                                  marginTop: "1.5rem",
                                  "&:hover": {
                                    backgroundColor: "#64b5f6",
                                    fontWeight: "600",
                                  },
                                }}
                                onClick={() => handleSelectPlan(plan)}
                              >
                                Select
                              </Button>
                            </Box>
                          </CardContent>
                        </Box>
                      </FlippingCard>
                    </Grid>
                  ))}
                </Grid>
                <Modal open={confirmationOpen} onClose={handleCancel}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      borderRadius: "10px",
                    }}
                  >
                    <Typography style={{ fontSize: "18px" }}>
                      Your existing plan will be replaced. Are you sure you want
                      to proceed?
                    </Typography>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        marginTop: "22px",
                      }}
                    >
                      <Grid>
                        <Button
                          onClick={handleConfirmProceed}
                          variant="contained"
                          className="greeny"
                          size="small"
                        >
                          Proceed
                        </Button>
                      </Grid>
                      <Grid>
                        <Button
                          onClick={handleCancel}
                          variant="contained"
                          className="pinky"
                          size="small"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
                <Modal open={DegradeOpen} onClose={handleDegradeCancel}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      borderRadius: "10px",
                    }}
                  >
                    <Typography style={{ fontSize: "18px" }}>
                      Plans Cannot Be degraded Kindly Contact Support
                    </Typography>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        marginTop: "22px",
                      }}
                    >
                      <Grid>
                        <Button
                          onClick={handleDegradeCancel}
                          variant="contained"
                          className="pinky"
                          size="small"
                        >
                          Ok
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              </Grid>
            ))}
          </Box>
        </Paper>

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="choose-payment-method"
          aria-describedby="choose-your-preferred-payment-method"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <Typography
              id="choose-payment-method"
              variant="h6"
              component="h2"
              align="center"
              fontWeight={"bold"}
            >
              Choose Payment Method
            </Typography>
     <RadioGroup
              aria-label="payment-method"
              name="payment-method"
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              {region !== "IN" && (
                <>
                  <FormControlLabel
                    value="PayPal"
                    control={<Radio />}
                    label="PayPal"
                  />
                  {/* <FormControlLabel
                    value="Stripe"
                    control={<Radio />}
                    label="Stripe"
                  /> */}
                </>
              )}
              {region === "IN" && (
                <>
                  <FormControlLabel
                    value="Razorpay"
                    control={<Radio />}
                    label="Razorpay"
                  />
                </>
              )}
            </RadioGroup>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleProceed}
              sx={{ mt: 2 }}
            >
              Proceed
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  ) : (
    <Payment
      exchangeRate={exchangeRate}
      region={region}
      currencySymbol={currencySymbol}
      currency={currency}
      plan={selectedPlan}
      paymentType={paymentType}
      onCancel={() => setView("pricing")}
    />
  );
};

export default Purchase;
